import { createFeatureSelector, createSelector } from "@ngrx/store";
import { userFeatureKey, UserState } from "./user.reducer";

export const selectUserState = createFeatureSelector<UserState>(userFeatureKey);

export const selectCurrentUser = createSelector(selectUserState,(state) => state.user);
export const selectCurrentUserDealer = createSelector(selectUserState,(state) => state.user?.dealer);
export const selectCurrentUserDealerName = createSelector(selectUserState,(state) => state.user?.dealer.name);
export const selectCurrentUserEmail= createSelector(selectUserState,(state) => state.user?.email);
export const selectCurrentUserId = createSelector(selectUserState,(state) => state.user?.id);
export const selectCurrentUserName = createSelector(selectUserState,(state) => state.user?.name);
export const selectCurrentUserPermissions = createSelector(selectUserState,(state) => state.user?.permissions);
export const selectCurrentUserPhone = createSelector(selectUserState,(state) => state.user?.phone);
export const selectCurrentUserToken = createSelector(selectUserState,(state) => state.user?.token);
export const selectCurrentUserUsername = createSelector(selectUserState,(state) => state.user?.username);

export const selectUserDealers = createSelector(selectUserState,(state) => state.dealers)