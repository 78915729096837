import { createReducer, on } from "@ngrx/store";
import { User, UserDealer } from "../../../shared/interfaces/user.interface";
import { UserActions } from "./user.actions";

export const userFeatureKey = 'User';

export interface UserState {
  isLoading: boolean;
  error: unknown;
  user: User | null;
  dealers: UserDealer[] | null
}

export const initialState: UserState = {
  isLoading: false,
  error: null,
  user: null,
  dealers: null
}

export const userReducer = createReducer(
  initialState,

  on(UserActions.setUser, (state) => { return { ...state, isLoading: true, }; }),
  on(UserActions.setUserSuccess, (state, action) => { return { ...state, user: action.data, isLoading: false, error: null } }),
  on(UserActions.setUserFailure, (state, action) => { return { ...state, error: action.error, isLoading: false, user: null } }),

  on(UserActions.loadUserDealers, (state) => { return { ...state, isLoading: true, }; }),
  on(UserActions.loadUserDealersSuccess, (state, action) => { return { ...state, dealers: action.data, isLoading: false, error: null } }),
  on(UserActions.loadUserDealersFailure, (state, action) => { return { ...state, error: action.error, isLoading: false, user: null } }),

  on(UserActions.changeUserDealer, (state) => { return { ...state, isLoading: true, }; }),
  on(UserActions.changeUserDealerSuccess, (state, action) => { return { ...state, user: action.data, isLoading: false, error: null } }),
  on(UserActions.changeUserDealerFailure, (state, action) => { return { ...state, error: action.error, isLoading: false, user: null } }),
  
  on(UserActions.unsetUser, () => initialState),
)
